import { Route, useLocation } from 'react-router-dom';

import Animations from './Animations';
import Header from './Header';
import Resume from './Resume';
import SimRacing from './SimRacing';
import Wedding from './Wedding';
import WeddingEvents from './WeddingEvents';
import WeddingTravel from './WeddingTravel';

import '../styles/AppStyles.scss';

function App() {
  const location = useLocation();

  return (
    <div className="App">
      {!location.pathname.includes('wedding') &&
        <Header />
      }
      <Route exact path="/" component={Resume} />
      <Route exact path="/animations" component={Animations} />
      <Route exact path="/simracing" component={SimRacing} />
      <Route exact path="/wedding" component={Wedding} />
      <Route exact path="/wedding/travel" component={WeddingTravel} />
      <Route exact path="/wedding/events" component={WeddingEvents} />
    </div>
  );
}

export default App;
