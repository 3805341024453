import React from 'react';

import ContentTitle from './ContentTitle';

import '../styles/ResumeStyles.scss';
import headshot from '../images/TuringHeadshot.jpg';

const Resume = () => (
  <div className="resume">
    <div className="profile">
      <ContentTitle title="Profile" />
      <div className="profile-info">
        <div className="profile-statement">
          Steadfast, life-long student fuses a background in spinal cord research with devotion to crafting performant applications. Adept at performing in high-pressure situations, both in groups and individually.
          <br/>
          <br/>
          I'm currently a senior software engineer working for Guild Education. As a self-proclaimed nerd, programming marries together my love of creating things with my more pedantic attention to detail.
          <br/>
          <br/>
          Outside of work, my spare time is spent between online sim-racing and making acrylic/oil art pieces. I'm making up for lost 2020 time by attending every concert I can find.
        </div>
        <div className="profile-image">
          <img src={headshot} className="headshot-image" alt="headshot-image" />
        </div>
      </div>
    </div>
    <div className="employment">
      <div className="section">
        <div className="section-title">
          Employment
        </div>
        <Company
          companyName="Guild Education"
          dates="June 2021 - Present"
          description="At Guild I work on an internal benefits provisioning tool, which handles the availability of academic programs and funding packages for the employee populations of our clients."
          languages="React, Typescript, Node.js, Python, Sass"
          link="www.GuildEducation.com"
          location="Denver, CO"
          product="Internal Benefits Provisioning Platform"
          technologies="Amazon Web Services (Lambda, CloudFormation, RDS), GraphQL"
          testing="Jest"
          title="Senior Software Engineer 1"
        />
        <Company
          companyName="Sunrun"
          dates="May 2019 - May 2021"
          description="At Sunrun I was a part of a team working on an internal sales tool, taking prospective customer information and coordinating the process of solar panel system design and construction. A React/Redux app that orchestrated other backend microservices to obtain satellite imagery of a residence and subsequently automatically design multiple possible designs and layouts to suit a customer's needs. After an agreement is reached, document generation and transmittal is handled for permitting, metering, and state-specific agreements. Functions for multiple types of sales individuals from both internal and external sources, with built in appointment management and company-to-customer communication systems."
          languages="React, Typescript, Node.js, Python, Sass"
          link="www.Sunrun.com"
          location="Denver, CO"
          product="Internal Sales Platform"
          technologies="Amazon Web Services (Lambda, CloudFormation, SNS), Google Cloud Platform (BigTable / BigQuery), Apache Airflow / DAGs"
          testing="Enzyme, Jest, Sinon"
          title="Front-End Software Engineer"
          tools="Docker, Mixpanel"
        />
        <Company
          companyName="Electric Chili Co."
          dates="May 2017 - August 2021"
          description="At Electric Chili we built and maintained websites to suit our clients' needs."
          location="Denver, CO"
          product="Website Consulting"
          technologies="PHP, React, Wordpress"
          title="Partner"
        />
        <Company
          companyName="Granicus"
          dates="January 2017 - May 2019"
          description="During my time at Granicus I was a part of multiple teams, but primarily focused on a legacy application which handled video streaming of local city, county, and state level government services such as council meetings. Integrated media displays alongside the video could handle documents, images, and audio/video files while providing closed captioning and on-demand replayability. An extensive document and agenda management system was built in, allowing for permissioned users and groups to create, edit, and approve agendas to accompany the video streams before they occurred."
          languages="PHP (Slim / Laravel), Javascript, Ruby (Rails), React, MySQL, Postgres"
          link="www.Granicus.com"
          location="Denver, CO"
          product="Multi-tenant Streaming Video and Agenda Management SOA Applications"
          technologies="Apache, Docker, Electron, JQuery"
          testing="RSpec, PHPUnit, Codeception, Cypress"
          title="Full-Stack Software Engineer"
        />
        <Company
          companyName="eGauge Systems"
          dates="June 2016 - December 2016"
          description="While a part of eGauge, I worked on tabular and graphical representations and displays of electrical monitoring data for homes and businesses, which would record kWh information for connected appliances and solar panel systems."
          languages="Python (Django), Javascript, MySQL"
          link="www.eGauge.net"
          location="Boulder, CO"
          technologies="Apache, JQuery, Linux"
          title="Full-Stack Web Developer"
        />
      </div>
    </div>
    <div className="education">
      <div className="section">
        <div className="section-title">
          Education
        </div>
        <Company
          companyName="Turing School of Software & Design"
          dates="October 2015 - April 2016"
          languages="Ruby (Rails), Javascript"
          link="turing.io"
          location="Denver, CO"
          title="7 Month / 1600 Hour Full-Stack Web Development Program"
        />
        <Company
          companyName="University of Florida"
          dates="August 2006 - May 2010"
          link="www.ufl.edu"
          location="Gainesville, FL"
          title="Bachelor of Science in Psychology, with Anthropology and Classics minors"
        />
      </div>
    </div>
    <div className="projects">
      <div className="section">
        <div className="section-title">
          Projects
        </div>
        <Company
          companyName="Portal 2D"
          dates="Turing Final Module Group Project Challenge"
          languages="Node.js"
          link="gregoryarmstrong.github.io/portal2d/"
          title="Goal: Build a polished and production ready game using solely Javascript"
        />
      </div>
      <div className="bottom-animation">
        <div className="triangle-2" />
        <div className="triangle-1" />
        <div className="triangle-3" />
        <div className="triangle-4" />
        <div className="triangle-5" />
        <div className="triangle-6" />
        <div className="triangle-7" />
        <div className="triangle-8" />
        <div className="triangle-9" />
        <div className="triangle-10" />
        <div className="triangle-11" />
        <div className="triangle-12" />
      </div>
    </div>
  </div>
);

const Company = ({
  companyName,
  dates,
  description,
  languages,
  link,
  location,
  product,
  technologies,
  testing,
  tools,
  title,
}) => (
  <div className="company">
    <div className="company-info">
      {companyName &&
        <div className="company-info-item company-name">
          <b>
            {companyName}
          </b>
        </div>
      }
      {link &&
        <a
          className="company-info-item link"
          href={`https://${link}`}
          rel="noreferrer"
          target="_blank"
        >
          {link}
        </a>
      }
      {dates &&
        <div className="company-info-item">
          {dates}
        </div>
      }
      {location &&
        <div className="company-info-item">
          {location}
        </div>
      }
    </div>
    <div className="company-technical">
      {title &&
        <div className="company-title">
          <b>
            {title}
          </b>
        </div>
      }
      {product &&
        <div className="company-product">
          {product}
        </div>
      }
      {description &&
        <div className="company-description">
          {description}
        </div>
      }
      {languages &&
        <div className="company-languages">
          <b>Languages: </b>
          {languages}
        </div>
      }
      {technologies &&
        <div className="company-technologies">
          <b>Technologies: </b>
          {technologies}
        </div>
      }
      {testing &&
        <div className="company-testing">
          <b>Testing: </b>
          {testing}
        </div>
      }
      {tools &&
        <div className="company-tools">
          <b>Tools: </b>
          {tools}
        </div>
      }
    </div>
  </div>
);

export default Resume;
