import React from 'react';

import WeddingHeader from './WeddingHeader';
import WeddingFooter from './WeddingFooter';

import '../styles/WeddingStyles.scss';
import cuteCouple from '../images/img_5301.jpg';

const WeddingEvents = () => {
    return (
        <div className="wedding">
            <WeddingHeader />
            <div className="wedding-content">
                <div className="date-and-time">
                    <div className="border-box">
                        <div className="date">
                            Rehearsal Dinner
                        </div>
                        <div className="time">
                            For invited guests
                        </div>
                        <div className="time">
                            The Cactus Club
                        </div>
                        <div>
                            1621 Blake St, Denver, CO 80202
                        </div>
                        <div className="time">
                            Friday - April 8th, 2022
                            <br />
                            6-11pm
                        </div>
                    </div>
                </div>
                <div className="venue">
                    <div className="border-box-alternate">
                        <div className="date" >
                            Wedding &amp; Reception
                        </div>
                        <div className="time">
                            The Mercury Cafe
                        </div>
                        <div>
                            2199 California St, Denver, CO 80205
                        </div>
                        <div className="time">
                            The Rockies' second home game of the season begins at 6:10pm, early arrival is encouraged due to potential for traffic downtown
                        </div>
                        <div className="time" >
                            Saturday - April 9th, 2022
                            <br />
                            Arrival time: 5:30pm
                            <br />
                            Drinks Available
                            <br />
                            <br />
                            Ceremony &amp; Reception: 6:00pm - 1:30am
                            <br />
                            Attire: Semi-Formal
                        </div>
                    </div>
                </div>
                <div className="date-and-time">
                    <div className="border-box">
                        <div className="date">
                            After-Wedding Brunch
                        </div>
                        <div className="time">
                            All are welcome
                        </div>
                        <div className="time">
                            Denver Milk Market
                        </div>
                        <div>
                            1800 Wazee St #100, Denver, CO 80202
                        </div>
                        <div className="time">
                            Sunday - April 10th, 2022
                            <br />
                            10:30am
                        </div>
                    </div>
                </div>
                <img
                    alt="cute couple"
                    className="cute-couple"
                    src={cuteCouple}
                    width="66.66%"
                />
            </div>
            <WeddingFooter />
        </div>
    )
};

export default WeddingEvents;