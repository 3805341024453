import React from 'react';

import WeddingHeader from './WeddingHeader';
import WeddingFooter from './WeddingFooter';

import '../styles/WeddingStyles.scss';
import cuteCoupleTwo from '../images/PXL_20210828_001229022.jpg';

const WeddingTravel = () => {
    return (
        <div className="wedding">
            <WeddingHeader />
            <div className="wedding-content">
                <div className="venue">
                    <div className="border-box-alternate">
                        <div className="name date">
                            Denver Travel Information
                        </div>
                        <div>
                            The Denver airport has a light-rail train connection to Union Station in downtown.
                            <br />
                            <br />
                            It runs pretty frequently, depending on the time of day you're arriving or departing.
                            <br />
                            <br />
                            It's just as fast as a ride-share or taxi at the price of about $10 per person one-way.
                            <br />
                            <br />
                            If you're not renting a car at the airport it's a great alternative.
                            <br />
                            <br />
                            Information about the A-Line can be found here:
                            <br />
                            <br />
                            <a
                                className="text-color-link"
                                href="https://www.rtd-denver.com/fastracks/a-line"
                                target="_blank"
                            >
                                RTD A-Train &#40;Downtown &lt; &#45; &gt; Airport&#41;
                            </a>
                            <br />
                            <br />
                            In and around Denver, Lyft&#47;Uber are probably your best bet for getting around otherwise.
                        </div>
                    </div>
                </div>
                <div className="date-and-time">
                    <div className="border-box">
                        <div className="date">
                            Hotels
                        </div>
                        <div>
                            We have limited numbers of rooms in a block reservations for the following hotels, both located in Downtown Denver. Both are a half mile from the wedding venue:
                        </div>
                        <div>
                            <br />
                            <a
                                className="text-color-link-opposite"
                                href="https://www.marriott.com/hotels/travel/denac-ac-hotel-denver-downtown/"
                                target="_blank"
                            >
                                The AC Hotel
                            </a>
                            <br />
                            King Single rooms at $150/night
                        </div>
                        <div>
                            <a
                                className="text-color-link-opposite"
                                href="https://www.marriott.com/hotels/travel/denmd-le-meridien-denver-downtown/"
                                target="_blank"
                            >
                                Le Meridien
                            </a>
                            <br />
                            King Single rooms at $170/night
                        </div>
                        <div>
                            If they are of interest to you, please mention it in the RSVP email or reach out to us!
                        </div>
                    </div>
                </div>
                <div className="venue">
                    <div className="border-box-alternate">
                        <div className="name date">
                            Gifts
                        </div>
                        <div>
                            Please know that your presence on our special day is the greatest present of all!
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            However, if you do wish to celebrate with a gift, a contribution to our honeymoon fund would be warmly appreciated.
                        </div>
                    </div>
                </div>
                <img
                    className="cute-couple-two"
                    src={cuteCoupleTwo}
                    width="33.33%"
                />
            </div>
            <WeddingFooter />
        </div>
    )
};

export default WeddingTravel;