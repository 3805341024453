import React from 'react';

const Star = ({ src, maxSize, minSize = 5 }) => {
    const size = Math.random() * 30;
    const height = maxSize ?
            size < maxSize ?
                size :
                maxSize :
        size > minSize ?
            size :
            minSize;

    return <img
        className="star"
        src={src}
        height={height}
        width={height}
    />;
};

export default Star;