import React from 'react';
import { Link } from 'react-router-dom'

import '../styles/HeaderStyles.scss';

const Header = () => (
  <div className="header-container">
    <header>
      <HeaderItem text="Gregory Armstrong" isPrimary path="/" />
      <Separator />
      <HeaderItem text="Animations" path="/animations" />
      <Separator />
      <HeaderItem text="Sim Racing" path="/simracing" />
    </header>
    <div className="color-bar-1" />
    <div className="color-bar-2" />
    <div className="color-bar-3" />
    <div className="color-bar-4" />
    <div className="color-bar-5" />
    <div className="color-bar-6" />
    <div className="color-bar-7" />
    <div className="color-bar-8" />
    <div className="color-bar-9" />
    <div className="color-bar-10" />
    <div className="color-bar-11" />
  </div>
);

const HeaderItem = ({ isPrimary, path, text }) => (
  <Link className={isPrimary ? 'item-primary' : 'item'} to={path}>
    {text}
  </Link>
);

const Separator = () => (
  <div className="separator" />
);

export default Header;
