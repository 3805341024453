import React from 'react';

import Star from './Star';

import '../styles/WeddingHeaderStyles.scss';
import '../styles/WeddingFooterStyles.scss';

import starOne from '../images/477_1.svg';
import starTwo from '../images/477_2.svg';
import starThree from '../images/477_3.svg';
import starFour from '../images/477_4.svg';
import starFive from '../images/477_5.svg';
import starSix from '../images/477_7.svg';
import starSeven from '../images/477_8.svg';
import starEight from '../images/477_9.svg';
import starNine from '../images/477_10.svg';
import starTen from '../images/477_11.svg';
import starEleven from '../images/477_12.svg';
import starTwelve from '../images/477_13.svg';
import starThirteen from '../images/477_14.svg';
import starFourteen from '../images/477_15.svg';
import starFifteen from '../images/477_16.svg';

import starSixteen from '../images/476_1.svg';
import starSeventeen from '../images/476_2.svg';
import starEighteen from '../images/476_3.svg';
import starNineteen from '../images/476_4.svg';
import starTwenty from '../images/476_5.svg';
import starTwentyOne from '../images/476_6.svg';
import starTwentyTwo from '../images/476_7.svg';
import starTwentyThree from '../images/476_8.svg';
import starTwentyFour from '../images/476_9.svg';
import starTwentyFive from '../images/476_10.svg';
import starTwentySix from '../images/476_11.svg';
import starTwentySeven from '../images/476_12.svg';
import starTwentyEight from '../images/476_13.svg';
import starTwentyNine from '../images/476_14.svg';
import starThirty from '../images/476_15.svg';
import starThirtyOne from '../images/476_16.svg';

const WeddingFooter = () => (
    <div className="footer">
        <div className="stars">
            <Star src={starOne} maxSize={20} />
            <Star src={starTwo} maxSize={20} />
            <Star src={starThree} maxSize={20} />
            <Star src={starFour} maxSize={20} />
            <Star src={starNine} maxSize={20} />
            <Star src={starTen} maxSize={20} />
            <Star src={starEleven} maxSize={20} />
            <Star src={starTwelve} maxSize={20} />
            <Star src={starThirteen} maxSize={20} />
            <Star src={starFourteen} maxSize={20} />
            <Star src={starFifteen} maxSize={20} />
            <Star src={starSixteen} maxSize={20} />
            <Star src={starSeventeen} maxSize={20} />
            <Star src={starEighteen} maxSize={20} />
            <Star src={starNineteen} maxSize={20} />
            <Star src={starTwenty} maxSize={20} />
            <Star src={starTwentyOne} maxSize={20} />
            <Star src={starTwentyTwo} maxSize={20} />
            <Star src={starTwentyThree} maxSize={20} />
            <Star src={starTwentyFour} maxSize={20} />
        </div>
        <div className="starsTwo" >
            <Star src={starTwentySix} maxSize={15} />
            <Star src={starTwentyOne} maxSize={15} />
            <Star src={starTwo} maxSize={15} />
            <Star src={starTwentyFour} maxSize={15} />
            <Star src={starThree} maxSize={15} />
            <Star src={starTwentyThree} maxSize={15} />
            <Star src={starFour} maxSize={15} />
            <Star src={starTwentySeven} maxSize={15} />
            <Star src={starSeventeen} maxSize={15} />
            <Star src={starFive} maxSize={15} />
            <Star src={starSix} maxSize={15} />
            <Star src={starTwentyNine} maxSize={15} />
            <Star src={starSeven} maxSize={15} />
            <Star src={starEight} maxSize={15} />
            <Star src={starNineteen} maxSize={15} />
            <Star src={starThirteen} maxSize={15} />
            <Star src={starThirty} maxSize={15} />
            <Star src={starTwentyFive} maxSize={15} />
            <Star src={starFourteen} maxSize={15} />
            <Star src={starFifteen} maxSize={15} />
            <Star src={starSixteen} maxSize={15} />
            <Star src={starEighteen} maxSize={15} />
            <Star src={starTwenty} maxSize={15} />
            <Star src={starTwentyTwo} maxSize={15} />
            <Star src={starThirtyOne} maxSize={15} />
            <Star src={starOne} maxSize={15} />
        </div>
        <div className="starsThree" >
            <Star src={starThirtyOne} maxSize={17} />
            <Star src={starTwentyThree} maxSize={17} />
            <Star src={starTwentySeven} maxSize={17} />
            <Star src={starSeventeen} maxSize={17} />
            <Star src={starFive} maxSize={17} />
            <Star src={starSix} maxSize={17} />
            <Star src={starTwentyNine} maxSize={17} />
            <Star src={starSixteen} maxSize={17} />
            <Star src={starSeven} maxSize={17} />
            <Star src={starTwentyEight} maxSize={17} />
            <Star src={starNine} maxSize={17} />
            <Star src={starTen} maxSize={17} />
            <Star src={starEleven} maxSize={17} />
            <Star src={starTwelve} maxSize={17} />
            <Star src={starNineteen} maxSize={17} />
            <Star src={starThirteen} maxSize={17} />
            <Star src={starThirty} maxSize={17} />
            <Star src={starTwentyFive} maxSize={17} />
            <Star src={starFourteen} maxSize={17} />
            <Star src={starFifteen} maxSize={17} />
            <Star src={starEighteen} maxSize={17} />
            <Star src={starTwenty} maxSize={17} />
            <Star src={starFour} maxSize={17} />
            <Star src={starTwentyTwo} maxSize={17} />
            <Star src={starOne} maxSize={17} />
            <Star src={starEight} maxSize={17} />
        </div>
        <div className="starsFour">
            <Star src={starTwentySix} maxSize={15} />
            <Star src={starTwentyOne} maxSize={15} />
            <Star src={starTwo} maxSize={15} />
            <Star src={starTwentyFour} maxSize={15} />
            <Star src={starThree} maxSize={15} />
            <Star src={starThirtyOne} maxSize={15} />
            <Star src={starTwentyThree} maxSize={15} />
            <Star src={starTwentySeven} maxSize={15} />
            <Star src={starSeventeen} maxSize={15} />
            <Star src={starFive} maxSize={15} />
            <Star src={starSix} maxSize={15} />
            <Star src={starTwentyNine} maxSize={15} />
            <Star src={starSixteen} maxSize={15} />
            <Star src={starSeven} maxSize={15} />
            <Star src={starTwentyEight} maxSize={15} />
            <Star src={starNine} maxSize={15} />
            <Star src={starTen} maxSize={15} />
            <Star src={starEleven} maxSize={15} />
            <Star src={starTwelve} maxSize={15} />
            <Star src={starNineteen} maxSize={15} />
            <Star src={starThirteen} maxSize={15} />
            <Star src={starThirty} maxSize={15} />
            <Star src={starTwentyFive} maxSize={15} />
            <Star src={starFourteen} maxSize={15} />
            <Star src={starFifteen} maxSize={15} />
            <Star src={starEighteen} maxSize={15} />
            <Star src={starTwenty} maxSize={15} />
            <Star src={starFour} maxSize={15} />
            <Star src={starTwentyTwo} maxSize={15} />
            <Star src={starOne} maxSize={15} />
            <Star src={starEight} maxSize={15} />
        </div>
        <div className="starsFive">
            <Star src={starSeventeen} maxSize={12} />
            <Star src={starFive} maxSize={12} />
            <Star src={starSix} maxSize={12} />
            <Star src={starTwenty} maxSize={12} />
            <Star src={starTwentyNine} maxSize={12} />
            <Star src={starSixteen} maxSize={12} />
            <Star src={starSeven} maxSize={12} />
            <Star src={starTwentySix} maxSize={12} />
            <Star src={starEighteen} maxSize={12} />
            <Star src={starTwentyOne} maxSize={12} />
            <Star src={starNine} maxSize={12} />
            <Star src={starTen} maxSize={12} />
            <Star src={starTwelve} maxSize={12} />
            <Star src={starNineteen} maxSize={12} />
            <Star src={starTwo} maxSize={12} />
            <Star src={starTwentyFour} maxSize={12} />
            <Star src={starThree} maxSize={12} />
            <Star src={starEleven} maxSize={12} />
            <Star src={starThirtyOne} maxSize={12} />
            <Star src={starTwentyThree} maxSize={12} />
            <Star src={starTwentySeven} maxSize={12} />
            <Star src={starTwentyEight} maxSize={12} />
            <Star src={starThirteen} maxSize={12} />
            <Star src={starThirty} maxSize={12} />
            <Star src={starTwentyFive} maxSize={12} />

        </div>
        <div className="starsSix">
            <Star src={starTwentySix} maxSize={7} />
            <Star src={starTwentyOne} maxSize={7} />
            <Star src={starTwentyFive} maxSize={7} />
            <Star src={starSeventeen} maxSize={7} />
            <Star src={starFifteen} maxSize={7} />
            <Star src={starFour} maxSize={7} />
            <Star src={starTwentyTwo} maxSize={7} />
            <Star src={starOne} maxSize={7} />
            <Star src={starEight} maxSize={7} />
            <Star src={starTwentySeven} maxSize={7} />
            <Star src={starFourteen} maxSize={7} />
            <Star src={starFive} maxSize={7} />
            <Star src={starNine} maxSize={7} />
            <Star src={starTwo} maxSize={7} />
            <Star src={starTwentyFour} maxSize={7} />
            <Star src={starThree} maxSize={7} />
            <Star src={starEleven} maxSize={7} />
            <Star src={starThirtyOne} maxSize={7} />
            <Star src={starTwentyThree} maxSize={7} />
            <Star src={starTwelve} maxSize={7} />
            <Star src={starEighteen} maxSize={7} />
            <Star src={starNineteen} maxSize={7} />
            <Star src={starThirteen} maxSize={7} />
            <Star src={starThirty} maxSize={7} />
            <Star src={starSix} maxSize={7} />
            <Star src={starTwenty} maxSize={7} />
            <Star src={starTwentyNine} maxSize={7} />
            <Star src={starSixteen} maxSize={7} />
            <Star src={starSeven} maxSize={7} />
            <Star src={starTwentyEight} maxSize={7} />
            <Star src={starTen} maxSize={7} />
        </div>
    </div>
);

export default WeddingFooter;