import React, { Component } from 'react';
// import { TimelineLite } from 'gsap';

import ContentTitle from './ContentTitle';

import '../styles/AnimationsStyles.scss';
import '../styles/animations/OneStyles.scss';
import '../styles/animations/TwoStyles.scss';
import '../styles/animations/ThreeStyles.scss';
import '../styles/animations/FourStyles.scss';

const generateAnimationObjects = (number, animationNumber) => {
  const objects = [];
  for (let i = 0; i <= number; i++) {
    objects.push(<div className={`animation-${animationNumber}-object`} />);
  }
  return objects;
}

class Animations extends Component {
  constructor(props) {
    super(props);

    this.myElements = [];
    // this.myTween = new TimelineLite({ paused: true });
    this.state = {
      animationOneObjects: generateAnimationObjects(Math.floor(Math.random() * 100), 'one'),
      animationTwoObjects: generateAnimationObjects(Math.floor(Math.random() * 50), 'two'),
      displayAnimationOne: false,
      displayAnimationTwo: false,
      displayAnimationThree: false,
      displayAnimationFour: false,
      displayLoadingAnimation: true,
    }

    this.hideDisplayAnimation = this.hideDisplayAnimation.bind(this);
    this.showDisplayAnimation = this.showDisplayAnimation.bind(this);
  }

  componentDidMount() {
    window.setTimeout(() => {
      this.setState({
        displayLoadingAnimation: false,
        displayAnimationOne: true,
      })},
      2000,
    );
    // this.myTween.staggerTo()
  }

  hideDisplayAnimation(number) {
    this.setState({
      ['displayAnimation' + number[0].toUpperCase() + number.substring(1)]: false,
    });
  }

  showDisplayAnimation(animationNumber, objectNumber) {
    const generatedAnimationObjects = objectNumber ? {
      ['animation' + animationNumber[0].toUpperCase() + animationNumber.substring(1) + 'Objects']: generateAnimationObjects(
        Math.floor(Math.random() * objectNumber),
        animationNumber,
      )
    } : {};
    this.setState({
      displayAnimationOne: false,
      displayAnimationTwo: false,
      displayAnimationThree: false,
      displayAnimationFour: false,
      ...generatedAnimationObjects,
      ['displayAnimation' + animationNumber[0].toUpperCase() + animationNumber.substring(1)]: true,
    });
  }

  render() {
    const {
      animationOneObjects,
      animationTwoObjects,
      displayAnimationOne,
      displayAnimationTwo,
      displayAnimationThree,
      displayAnimationFour,
      displayLoadingAnimation,
    } = this.state;

    return (
      <div className="animations">
        {displayLoadingAnimation &&
          <div class="loading">
            <div class="bar" />
            <div class="bar" />
            <div class="bar" />
            <div class="bar" />
            <div class="bar" />
          </div>
        }
        {(!displayLoadingAnimation || displayAnimationFour) &&
          <>
            <ContentTitle title="Animations" />
            <div className="animation-left" />
            <div className="animation-right" />
            <div className="animation-bottom" />
            <div className="description">
              Dynamic animations made solely with CSS, best viewed on laptop/desktop. Toggling animation one or two will reset the animation with a random number of objects.
            </div>
            <div className="choices">
              <div
                className={`choice ${displayAnimationOne ? 'active' : ''}`}
                onClick={displayAnimationOne ?
                  () => this.hideDisplayAnimation('one') :
                  () => this.showDisplayAnimation('one', 100)
                }
              >
                One
              </div>
              <div
                className={`choice ${displayAnimationTwo ? 'active' : ''}`}
                onClick={displayAnimationTwo ?
                  () => this.hideDisplayAnimation('two') :
                  () => this.showDisplayAnimation('two', 50)
                }
              >
                Two
              </div>
              <div
                className={`choice ${displayAnimationThree ? 'active' : ''}`}
                onClick={displayAnimationThree ?
                  () => this.hideDisplayAnimation('three') :
                  () => this.showDisplayAnimation('three')
                }
              >
                Three
              </div>
              <div
                className={`choice ${displayAnimationFour ? 'active' : ''}`}
                onClick={displayAnimationFour ?
                  () => this.hideDisplayAnimation('four') :
                  () => {
                    this.setState({ displayLoadingAnimation: true });
                    this.showDisplayAnimation('four');
                    window.setTimeout(() => {
                      this.setState({
                        displayLoadingAnimation: false,
                      })},
                      2000,
                    );
                  }
                }
              >
                Four
              </div>
            </div>
            <div className="animation">
              {displayAnimationOne &&
                <div className="animation-one">
                  {animationOneObjects}
                </div>
              }
              {displayAnimationTwo &&
                <div className="animation-two">
                  {animationTwoObjects}
                </div>
              }
              {displayAnimationThree &&
                <div className="animation-three">
                  <div className="first">
                    Gregory
                  </div>
                  <div className="last">
                    Armstrong
                  </div>
                </div>
              }
              {displayAnimationFour &&
                <div className="animation-four">
                  <div className="bubble" />
                  <div className="bubble x2" />
                  <div className="bubble x3" />
                  <div className="bubble x4" />
                  <div className="bubble x5" />
                  <div className="bubble x6" />
                  <div className="bubble x7" />
                  <div className="bubble x8" />
                  <div className="bubble x9" />
                  <div className="bubble asteroid a1" />
                  <div className="bubble asteroid a2" />
                  <div className="bubble asteroid a3" />
                  <div className="bubble asteroid a4" />
                  <div className="bubble asteroid a5" />
                  <div className="bubble asteroid a6" />
                  <div className="bubble asteroid a7" />
                  <div className="bubble asteroid a8" />
                  <div className="bubble asteroid a9" />
                  <div className="bubble asteroid a10" />
                  <div className="bubble asteroid a11" />
                  <div className="bubble asteroid a12" />
                  <div className="bubble asteroid a13" />
                  <div className="bubble asteroid a14" />
                  <div className="bubble asteroid a15" />
                  <div className="bubble comet" />
                </div>
              }
            </div>
          </>
        }
      </div>
    )
  };
};

export default Animations;
