import React from 'react';

import '../styles/ContentTitleStyles.scss';

const ContentTitle = ({ title }) => (
  <div className="content-title">
    {title}
  </div>
);

export default ContentTitle;
