import React from 'react';

import WeddingHeader from './WeddingHeader';
import WeddingFooter from './WeddingFooter';

import '../styles/WeddingStyles.scss';
import cuteCouple from '../images/img_5301.jpg';
import cuteCoupleTwo from '../images/PXL_20210828_001229022.jpg';

const Wedding = () => (
    <div className="wedding">
        <WeddingHeader />
        <div className="wedding-content">
            <img
                alt="cute couple"
                className="cute-couple"
                src={cuteCouple}
                width="66.66%"
            />
            <div className="date-and-time">
                <div className="border-box">
                    <div className="date" >
                        April 9th, 2022
                    </div>
                    <div className="time">
                        Arrival time: 5:30pm
                        <br />
                        Drinks Available
                    </div>
                    <div className="time">
                        The Rockies' second home game of the season begins at 6:10pm, early arrival is encouraged due to potential for traffic downtown
                    </div>
                    <div className="time" >
                        Ceremony &amp; Reception: 6:00pm - Late
                        <br />
                        Attire: Semi-Formal
                    </div>
                </div>
            </div>
            <div className="venue">
                <div className="border-box-alternate">
                    <div className="date">
                        Mercury Cafe
                    </div>
                    <div>
                        2199 California Street, Denver, CO 80205
                    </div>
                    <div className="time">
                        Venue requires all guests to be vaccinated against COVID-19
                    </div>
                    <div>
                        We love your kids, but due to limitations with physical space available for the event we're having an adults-only ceremony and reception. If this creates a burden with respect to your attendance please contact us and we'll work with you!
                    </div>
                </div>
            </div>
            <img
                className="cute-couple-two"
                src={cuteCoupleTwo}
                width="33.33%"
            />
        </div>
        <WeddingFooter />
    </div>
);

export default Wedding;