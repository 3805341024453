import React, { Component } from 'react';

import '../styles/SimRacingStyles.scss';

import racingScreenshotOne from '../images/dallararoadatlanta.png';
import racingScreenshotTwo from '../images/audir8monza.png';
import racingScreenshotThree from '../images/NextGenNascarNewHampshire.png';
import racingScreenshotFour from '../images/PorscheCupRoadAmerica.png';

class SimRacing extends Component {
  constructor(props) {
    super(props);

    this.state = { displayLoadingAnimation: true };
  }
  componentDidMount() {
    window.setTimeout(() => {
      this.setState({
        displayLoadingAnimation: false,
      })},
      3000,
    );
  }

  render() {
    const { displayLoadingAnimation } = this.state;

    return (
      <div className="sim-racing">
        {displayLoadingAnimation &&
          <div className="loading-animation">
            <div className="loading-item">
              #87
            </div>
          </div>
        }
        <div className="racing-images">
          <img src={racingScreenshotTwo} className="racing-image" alt="racing-image" />
        </div>
        <div className="information">
          <div className="person">
            Gregory Armstrong
            <div className="number">
              #87
            </div>
          </div>
          <div className="ratings">
            <div className="licenses">
              <div className="license">
                <div className="type bold">
                  Road
                </div>
                <div className="points">
                  <div className="point">
                    iRating 2242
                  </div>
                  <div className="point">
                    Safety Rating: A 4.49
                  </div>
                </div>
              </div>
              <div className="license">
                <div className="type bold">
                  Oval
                </div>
                <div className="points">
                  <div className="point">
                    iRating 2183
                  </div>
                  <div className="point">
                    Safety Rating: A 2.49
                  </div>
                </div>
              </div>
            </div>
            <div className="results">
              <div className="header">
                <div className="header-item">
                  Category
                </div>
                <div className="header-item">
                  Starts
                </div>
                <div className="header-item">
                  Wins
                </div>
                <div className="header-item">
                  Top 5
                </div>
                <div className="header-item">
                  Poles
                </div>
                <div className="header-item">
                  Avg Start
                </div>
                <div className="header-item">
                  Avg Finish
                </div>
                <div className="header-item">
                  Total Laps
                </div>
                <div className="header-item">
                  Laps Led
                </div>
                <div className="header-item">
                  Inc/Race
                </div>
                <div className="header-item">
                  Pts/Race
                </div>
                <div className="header-item">
                  Win %
                </div>
                <div className="header-item">
                  Top 5 %
                </div>
                <div className="header-item">
                  Laps Led %
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Road Career
                </div>
                <div className="stats-item-cell">
                  1013
                </div>
                <div className="stats-item-cell">
                  30
                </div>
                <div className="stats-item-cell">
                  264
                </div>
                <div className="stats-item-cell">
                  18
                </div>
                <div className="stats-item-cell">
                  12
                </div>
                <div className="stats-item-cell">
                  10
                </div>
                <div className="stats-item-cell">
                  21211
                </div>
                <div className="stats-item-cell">
                  336
                </div>
                <div className="stats-item-cell">
                  6.2695
                </div>
                <div className="stats-item-cell">
                  54
                </div>
                <div className="stats-item-cell">
                  2.96
                </div>
                <div className="stats-item-cell">
                  26.06
                </div>
                <div className="stats-item-cell">
                  1.58
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Oval Career
                </div>
                <div className="stats-item-cell">
                  313
                </div>
                <div className="stats-item-cell">
                  5
                </div>
                <div className="stats-item-cell">
                  50
                </div>
                <div className="stats-item-cell">
                  1
                </div>
                <div className="stats-item-cell">
                  19
                </div>
                <div className="stats-item-cell">
                  13
                </div>
                <div className="stats-item-cell">
                  19465
                </div>
                <div className="stats-item-cell">
                  416
                </div>
                <div className="stats-item-cell">
                  4.7476
                </div>
                <div className="stats-item-cell">
                  61
                </div>
                <div className="stats-item-cell">
                  1.6
                </div>
                <div className="stats-item-cell">
                  15.97
                </div>
                <div className="stats-item-cell">
                  2.14
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Road 2022
                </div>
                <div className="stats-item-cell">
                  92
                </div>
                <div className="stats-item-cell">
                  2
                </div>
                <div className="stats-item-cell">
                  14
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  14
                </div>
                <div className="stats-item-cell">
                  11
                </div>
                <div className="stats-item-cell">
                  1645
                </div>
                <div className="stats-item-cell">
                  49
                </div>
                <div className="stats-item-cell">
                  6.2935
                </div>
                <div className="stats-item-cell">
                  66
                </div>
                <div className="stats-item-cell">
                  2.17
                </div>
                <div className="stats-item-cell">
                  15.22
                </div>
                <div className="stats-item-cell">
                  2.98
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Oval 2022
                </div>
                <div className="stats-item-cell">
                  16
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  19
                </div>
                <div className="stats-item-cell">
                  15
                </div>
                <div className="stats-item-cell">
                  640
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  4.0625
                </div>
                <div className="stats-item-cell">
                  59
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  0
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Road 2021
                </div>
                <div className="stats-item-cell">
                  246
                </div>
                <div className="stats-item-cell">
                  3
                </div>
                <div className="stats-item-cell">
                  64
                </div>
                <div className="stats-item-cell">
                  1
                </div>
                <div className="stats-item-cell">
                  12
                </div>
                <div className="stats-item-cell">
                  10
                </div>
                <div className="stats-item-cell">
                  5495
                </div>
                <div className="stats-item-cell">
                  15
                </div>
                <div className="stats-item-cell">
                  6.0571
                </div>
                <div className="stats-item-cell">
                  61
                </div>
                <div className="stats-item-cell">
                  1.22
                </div>
                <div className="stats-item-cell">
                  26.02
                </div>
                <div className="stats-item-cell">
                  0.27
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Oval 2021
                </div>
                <div className="stats-item-cell">
                  107
                </div>
                <div className="stats-item-cell">
                  3
                </div>
                <div className="stats-item-cell">
                  15
                </div>
                <div className="stats-item-cell">
                  1
                </div>
                <div className="stats-item-cell">
                  20
                </div>
                <div className="stats-item-cell">
                  14
                </div>
                <div className="stats-item-cell">
                  7034
                </div>
                <div className="stats-item-cell">
                  55
                </div>
                <div className="stats-item-cell">
                  4.2523
                </div>
                <div className="stats-item-cell">
                  78
                </div>
                <div className="stats-item-cell">
                  2.8
                </div>
                <div className="stats-item-cell">
                  14.02
                </div>
                <div className="stats-item-cell">
                  0.78
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Road 2020
                </div>
                <div className="stats-item-cell">
                  331
                </div>
                <div className="stats-item-cell">
                  16
                </div>
                <div className="stats-item-cell">
                  99
                </div>
                <div className="stats-item-cell">
                  13
                </div>
                <div className="stats-item-cell">
                  12
                </div>
                <div className="stats-item-cell">
                  10
                </div>
                <div className="stats-item-cell">
                  7029
                </div>
                <div className="stats-item-cell">
                  215
                </div>
                <div className="stats-item-cell">
                  6.203
                </div>
                <div className="stats-item-cell">
                  53
                </div>
                <div className="stats-item-cell">
                  4.83
                </div>
                <div className="stats-item-cell">
                  29.91
                </div>
                <div className="stats-item-cell">
                  3.06
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Oval 2020
                </div>
                <div className="stats-item-cell">
                  123
                </div>
                <div className="stats-item-cell">
                  2
                </div>
                <div className="stats-item-cell">
                  20
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  19
                </div>
                <div className="stats-item-cell">
                  14
                </div>
                <div className="stats-item-cell">
                  7622
                </div>
                <div className="stats-item-cell">
                  134
                </div>
                <div className="stats-item-cell">
                  5.2114
                </div>
                <div className="stats-item-cell">
                  56
                </div>
                <div className="stats-item-cell">
                  1.63
                </div>
                <div className="stats-item-cell">
                  16.26
                </div>
                <div className="stats-item-cell">
                  1.76
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Road 2019
                </div>
                <div className="stats-item-cell">
                  337
                </div>
                <div className="stats-item-cell">
                  9
                </div>
                <div className="stats-item-cell">
                  86
                </div>
                <div className="stats-item-cell">
                  4
                </div>
                <div className="stats-item-cell">
                  12
                </div>
                <div className="stats-item-cell">
                  11
                </div>
                <div className="stats-item-cell">
                  6940
                </div>
                <div className="stats-item-cell">
                  57
                </div>
                <div className="stats-item-cell">
                  6.4573
                </div>
                <div className="stats-item-cell">
                  48
                </div>
                <div className="stats-item-cell">
                  2.67
                </div>
                <div className="stats-item-cell">
                  25.52
                </div>
                <div className="stats-item-cell">
                  0.82
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Oval 2019
                </div>
                <div className="stats-item-cell">
                  67
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  15
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  15
                </div>
                <div className="stats-item-cell">
                  11
                </div>
                <div className="stats-item-cell">
                  4169
                </div>
                <div className="stats-item-cell">
                  227
                </div>
                <div className="stats-item-cell">
                  4.8507
                </div>
                <div className="stats-item-cell">
                  45
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  22.39
                </div>
                <div className="stats-item-cell">
                  5.44
                </div>
              </div>
              <div className="stats-item">
                <div className="stats-item-cell bold">
                  Road 2018
                </div>
                <div className="stats-item-cell">
                  7
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  1
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  10
                </div>
                <div className="stats-item-cell">
                  9
                </div>
                <div className="stats-item-cell">
                  102
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  7.7143
                </div>
                <div className="stats-item-cell">
                  32
                </div>
                <div className="stats-item-cell">
                  0
                </div>
                <div className="stats-item-cell">
                  14.29
                </div>
                <div className="stats-item-cell">
                  0
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="racing-images-two">
          <img src={racingScreenshotThree} className="racing-image" id="racing-images-stack-one" alt="racing-image" />
          <img src={racingScreenshotOne} className="racing-image" id="racing-images-stack-two" alt="racing-image" />
          <img src={racingScreenshotFour} className="racing-image" id="racing-images-stack-three" alt="racing-image" />
        </div>
      </div>
    );
  };
};

export default SimRacing;
